@tailwind base;
@tailwind components;
@tailwind utilities;

.home {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    
  }
  .welcome-image {
    width: 20%;
    min-width: 0px;
    max-width: 200px;
  }
  
  .welcome-title {
    font-size: 4rem;
    color: #003755;
  }
  
  .welcome-subtitle {
    font-size: 1.8rem;
    color: #003755;
  }